<template>
    <div>
		<el-dialog :title="title" :visible.sync="open" width="500px" @close="commitOnClose">
			<el-form ref="saveOrEditForm" :model="saveData" label-width="120px">
				<el-form-item :label="translate('DICT.PARENT_ID')" prop="parentId">
					<el-input v-model="saveData.parentId" :disabled="parentIdReadOnly || !isAdd" :placeholder="translate('FROM.PLACEHOLDER', {param: 'DICT.PARENT_ID'})"/>
				</el-form-item>
				<el-form-item :label="translate('DICT.CODE')" prop="code">
					<el-input v-model="saveData.code" :disabled="!isAdd" :placeholder="translate('FROM.PLACEHOLDER', {param: 'DICT.CODE'})"/>
				</el-form-item>
				<el-form-item :label="translate('DICT.NAME')" prop="name">
					<el-input v-model="saveData.name" :placeholder="translate('FROM.PLACEHOLDER', {param: 'DICT.NAME'})"/>
				</el-form-item>
				<el-form-item :label="translate('DICT.VALUE')" prop="value">
					<el-input v-model="saveData.value" :placeholder="translate('FROM.PLACEHOLDER', {param: 'DICT.VALUE'})"/>
				</el-form-item>
				<el-form-item :label="translate('COMMON.SORT')" prop="sort">
					<el-input-number :min="0" v-model="saveData.sort" :placeholder="translate('FROM.PLACEHOLDER', {param: 'COMMON.SORT'})"/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">{{translate('FROM.SUBMIT')}}</el-button>
				<el-button type="primary" @click="cancel">{{translate('FROM.CANCEL')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {BasicApi} from '@/api';
	export default {
		name: "CustomForm",
		props: {
			dicts: {
				type: Object,
				default: function () {
					return {}
				}
			},
			isAdd: {
				type: Boolean,
				default: true,
			},
			selectRow: {
				type: Object,
				default: function () {
					return {}
				},
			},
			onFresh: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				title: '',
				parentIdReadOnly: false,
				saveData: {},
				open: false
			}
		},
		watch: {
			onFresh: function (value) {
				this.open = value;
				if (value) {
					this.resetData();
				}
			}
		},
		mounted() {
		},
		methods: {
			async resetData() {
				if (this.isAdd){
					this.parentIdReadOnly = !!this.selectRow.id;
					this.saveData = {
						parentId: (this.selectRow && this.selectRow.code) || ''
					};
					this.title = this.translate('FROM.ADD_MODULE', {module: 'Dict'});
				}else {
					this.saveData = this.selectRow;
					this.isAdd = false;
					this.title = this.translate('FROM.UPDATE_MODULE', {module: 'Dict'});
				}
			},
			async submitForm() {
				let result = (this.isAdd) ? (await BasicApi.dict.save(this.saveData)) : (await BasicApi.dict.edit(this.saveData.id, this.saveData));
				if (result.success) {
					this.Message.success(this.translate(this.isAdd ? 'FROM.ADD_SUCCESS' : 'FROM.UPDATE_SUCCESS'));
					this.commitOnClose();
				}
			},
			cancel() {
				this.saveData = {};
				this.commitOnClose();
			},
			commitOnClose() {
				this.$emit('on-close');
			}
		}
	};
</script>

<style scoped>

</style>
